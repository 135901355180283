<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        :to="{ name: 'offers-add' }"
        variant="primary"
      >
        Add Offer
      </b-button>
    </div>

    <b-card header="Search">
      <b-form-row>
        <b-col md="6">
          <b-form-group
            label="Name / Description"
            label-for="search"
          >
            <search
              placeholder="Name / Description"
              :search-query.sync="query.search"
              @refresh="$refs.table.refresh()"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="Store"
            label-for="search"
          >
            <search
              placeholder="Store"
              :search-query.sync="query.store"
              @refresh="$refs.table.refresh()"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Date"
            label-for="date-range-filter"
          >
            <date-range-filter :date-range.sync="query.range" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="status-select"
          >
            <status-filter :status.sync="query.status" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Offer Tags"
            label-for="offer-tags-select"
          >
            <offer-tag-select
              multiple
              :offer-tag.sync="query.tagIds"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-row align-h="end">
        <b-col
          md="2"
          class="ml-auto"
        >
          <search-button @refresh="$refs.table.refresh()" />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-table
        ref="table"
        :items="getOffers"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
        show-empty
      >
        <template #cell(actions)="{ item }">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'offers-view', params: { id: item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'offers-feedback-reports', params: { id: item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Feedback Reports</span>
            </b-dropdown-item>
            <share-deep-link
              :id="item.id"
              route="/offers_details"
              :additional-params="{
                classification: 'scrapped',
                id: item.id,
                image: item.image,
                description:
                  'Check this amazing deal on MuslimDo! Sharing is caring. find out more offers and promotions on the first exclusive Halal Offers platform(DoOffers) and share with friends!',
              }"
            />
            <b-dropdown-item
              :to="{ name: 'offers-edit', params: { id: item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteOffer(item)">
              <div class="text-danger">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import OfferTagSelect from '@/common/components/GeneralSettings/offer-tags/OfferTagSelect.vue'
import Offers from '@/common/compositions/Offers/OffersApi'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import ShareDeepLink from '@/common/components/common/Table/ShareDeepLink.vue'
import StatusFilter from '@/common/components/common/FormInputs/StatusFilter.vue'

export default {
  name: 'OffersList',
  components: {
    OfferTagSelect,
    Pagination,
    Search,
    DateRangeFilter,
    SearchButton,
    ShareDeepLink,
    StatusFilter,
  },
  data() {
    return {
      query: {
        range: [],
        tagIds: [],
        status: null,
      },
    }
  },
  computed: {
    fields() {
      return [
        { key: 'title', sortable: true },
        { key: 'store_name', sortable: true },
        { key: 'category', formatter: v => v?.name, sortable: true },
        { key: 'start_date', sortable: true },
        {
          key: 'status',
          formatter: v => (v ? 'Active' : 'Inactive'),
          sortable: true,
        },
        { key: 'end_date', sortable: true },
        'actions',
      ]
    },
    formattedQuery() {
      return {
        search: this.query.search,
        store: this.query.store,
        status: this.query.status,
        startTime: this.query.range[0],
        endTime: this.query.range[1],
        tagIds: this.query.tagIds.map(offer => offer.id),
      }
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { confirmOperation, successfulDeletionAlert } = handleAlerts()
    const { listOffersRequest, deleteOfferRequest } = Offers()
    return {
      pagination,
      listOffersRequest,
      confirmOperation,
      successfulDeletionAlert,
      deleteOfferRequest,
    }
  },
  methods: {
    getOffers(ctx) {
      return this.listOffersRequest({
        ...this.formattedQuery,
        page: this.pagination.currentPage,
        orderParameter: ctx.sortBy,
        orderDir: ctx.sortDesc ? 'DESC' : 'ASC',
        classification: 'community',
      }).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data || []
      })
    },
    deleteOffer(item) {
      this.confirmOperation(
        `Are you sure you want to delete ${item.title} offer`,
      ).then(() => {
        this.deleteOfferRequest(item.id).then(() => {
          this.successfulDeletionAlert('Offer is deleted successfully')
          this.$refs.table.refresh()
        })
      })
    },
  },
}
</script>
<style lang="">
</style>
